<template>
  <div class="person-card">
    <router-link :to="{ name: 'persons.profile', params: { id: internalPerson.user_id }}">
      <base-avatar :user="internalPerson" size="big"></base-avatar>
    </router-link>
    <div class="info">
      <router-link :to="{ name: 'persons.profile', params: { id: internalPerson.user_id }}"
                   class="name">
        {{ internalPerson.nick_name }}
      </router-link>
    </div>

    <template v-if="$currentUser">
      <base-button size="small"
                   :loading="loading"
                   v-if="showFollowButton"
                   @click="follow">{{ $t('persons.personListItem.follow') }}</base-button>

      <template v-if="showIsFollowingHint">
        <div class="hint">{{ $t('persons.personListItem.alreadyFollowing') }}</div>
      </template>
    </template>
  </div>
</template>

<script>
  import { authenticated } from '@/services/auth.js'
  import profileService from '@/services/queries/profileQueries.js'

  export default {
    props: {
      person: {
        required: true,
        type: Object
      }
    },

    data() {
      return {
        internalPerson: null,
        loading: false
      }
    },

    created() {
      this.internalPerson = _.cloneDeep(this.person)
    },

    methods: {
      follow() {
        if (!authenticated()) {
          this.openAuthModal({ content: 'login' })
          return
        }

        this.loading = true
        profileService.follow(this.internalPerson.user_id).then(() =>{
          this.loading = false
          this.internalPerson.isFollowing = true
        })
      },

      ...mapActions('system', [
        'openAuthModal'
      ])
    },

    computed: {
      showFollowButton() {
        if (this.$currentUser.user_id === this.internalPerson.user_id) {
          return false
        }

        return !this.internalPerson.isFollowing
      },

      showIsFollowingHint() {
        if (this.$currentUser.user_id === this.internalPerson.user_id) {
          return false
        }

        return this.internalPerson.isFollowing
      },
    }
  }
</script>

<style lang="scss" scoped>


  .person-card {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    float: left;

    width: 240px;
    height: 240px;

    background: #FFFFFF;
    box-shadow: 0 6px 10px 0 rgba(240,240,240,0.50);
    border-radius: 6px;

    padding: 20px;
  }

  .avatar {
    flex-basis: 100px;
    flex-shrink: 0;

    width: 100px;
    height: 100px;

    border-radius: 50%;

    background-color: $primary-color;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 20px 0;

    .name {
      font-size: 16px;
      margin-bottom: 6px;
    }
  }

  .hint {
    font-size: 14px;
    color: #727272;
  }
</style>
