<template>
  <div class="container">
    <div class="person-list-wrapper">
      <base-text-box-search class="search"
                            :placeholder="$t('persons.personList.searchPlaceholder')"
                            @input="onSearchInput"></base-text-box-search>

      <div class="person-list" v-infinite-scroll="loadMore">
        <person-list-item v-for="person in persons"
                          :key="person.client_id"
                          :person="person"
                          class="person-list-item"></person-list-item>
      </div>

      <div class="loader-wrapper" v-if="loading">
        <base-loader></base-loader>
      </div>
    </div>
  </div>
</template>

<script>
  import PersonListItem from '@/components/Persons/PersonListItem.vue'
  import profileService from '@/services/queries/profileQueries.js'
  import hasBackgroundMixin from '@/services/mixins/hasBackgroundMixin.js'

  export default {
    components: {
      PersonListItem
    },

    data() {
      return {
        loading: false,
        persons: [],

        limit: 50,
        offset: 0,
        total: 0,

        searchText: ''
      }
    },

    mixins: [hasBackgroundMixin],

    created() {
      this.loadPersons()
    },

    methods: {
      loadPersons(append = false) {
        let params = {}

        params.limit  = this.limit
        params.offset = this.offset
        params.search_text = this.searchText ? this.searchText : null
        params['look_count.min'] = 1

        this.loading = true

        profileService.get(params).then(data => {
          this.total = data.total_count

          let persons = data.items

          if (append) {
            this.persons.push(...persons)
          } else {
            this.persons = persons
          }

          this.loading = false
        })
      },

      loadMore() {
        this.offset = this.offset + this.limit

        if (this.offset > this.total) {
          return
        }

        this.loadPersons(true)
      },

      onSearchInput: _.debounce(function(value) {
        this.searchText = value
      }, 500)
    },

    watch: {
      searchText() {
        this.persons = []
        this.offset = 0

        this.loadPersons()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .person-list-wrapper {
    width: 1040px;
    margin: 20px auto;
  }

  .search {
    width: 300px;
    margin-left: 10px;
  }

  .person-list {
    padding-top: 20px;
  }

  .person-list-item {
    margin: 10px;
  }

  .loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 40px 0;
  }
</style>